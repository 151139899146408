import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { loginUser } from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Dropdown, Form } from "react-bootstrap";
import hide from "../../../assets/svg/hide.png";
// import LoginLeftPart from "../../../assets/images/modal.gif";
import EyeIcon from "../../../assets/svg/EyeIcon";
import { APP_CONST } from "../../../config/const";

import MumbaiSports from "../../../assets/images/icons/mumbaisports.png";

const LoginModal = ({ show, handleClose, handleModalShow }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { appDetails } = useSelector((state) => state.app);

  const validateData = () => {
    const { username, password } = formData;
    const newErrors = {};
    if (!username || username === "") {
      newErrors.username = "Username cannot be empty";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorData = validateData();
    if (Object.keys(errorData).length > 0) {
      setErrors(errorData);
    } else {
      let loginData = { ...formData };
      handleClose();
      dispatch(loginUser(loginData));
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
  };

  const loginDemoUser = () => {
    const payload = {
      username: "Demoreddy",
      password: "Abcd@1234",
    };
    dispatch(loginUser(payload));
  };
  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
        closeVariant="black"
      >
        <Modal.Title style={{ color: "#013862" }}>Login</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ background: "#013862" }}>
        <div className="login-section">
          {/* <div className="login-leftPart d-none">
            <img src={LoginLeftPart} className="signin" />
          </div> */}
          <div className="Login-rightPart">
            {/* <Modal.Title>Log in now!</Modal.Title> */}
            <div className="form-steps">
              <h2 className="h2">Welcome to</h2>
              <img
                className="login-modal-img"
                src={appDetails?.LOGO_URL}
                alt="Logo"
              />

              <Form>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Control
                    type="text"
                    placeholder="Enter Username*"
                    name="username"
                    onChange={handleChange}
                    isInvalid={!!errors.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.username}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3 position-relative"
                  controlId="formBasicPassword"
                >
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password*"
                    name="password"
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />
                  <span
                    className="icon"
                    onClick={() => setShowPassword((s) => !s)}
                  >
                    {showPassword ? <img src={hide} alt="" /> : <EyeIcon />}
                  </span>
                  <Form.Control.Feedback type="invalid">
                    {errors?.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="d-flex justify-content-end mb-3">
                  <a
                    className="form-link"
                    onClick={() => {
                      handleClose();
                      handleModalShow(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD);
                    }}
                  >
                    Password Recovery
                  </a>
                </div>
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  Log in
                </Button>
              </Form>
              <div className="sign-up mt-4">
                <Button
                  variant="primary"
                  className=""
                  style={{ width: "200px" }}
                  onClick={loginDemoUser}
                >
                  Log In With Demo Id
                </Button>
              </div>
              <div className="sign-up mt-4">
                Not a member?
                <br />
                <a
                  href="#"
                  onClick={() => {
                    handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.AUTO);
                    handleModalShow(APP_CONST.AUTH_MODAL.MOBILE_NUMBER);
                  }}
                >
                  Sign Up Now & Win More!
                </a>
              </div>

              <div className="sportsSection">
                <img src={MumbaiSports} />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default LoginModal;
