import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_CONST } from "../config/const";
import ModalWrapper from "./ModalWrapper";
import { useSelector } from "react-redux";

const BottomHead = () => {
  const { isAuth } = useSelector((state) => state.auth);
  console.log(isAuth, "isAuth");
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState(APP_CONST.AUTH_MODAL.LOGIN);

  const handleModalShow = (modalName) => {
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };
  const navigate = useNavigate();
  const headerItems = [
    {
      id: 1,
      name: "Home",
      key: "home",
      redirectionUrl: "/sports",
      // image: HomeICon,
      authRequired: false,
    },
    {
      id: 2,
      name: "In-Play",
      key: "inplay",
      redirectionUrl: "/spmsports/cricket",
      // image: Inplay,
      authRequired: false,
    },
    {
      id: 3,
      name: "Cricket",
      key: "cricket",
      redirectionUrl: "/spmsports/cricket",
      // image: Cricket,
      authRequired: false,
    },
    {
      id: 4,
      name: "Footbal",
      key: "footbal",
      redirectionUrl: "/spmsports/soccer",
      // image: Soccer,
      authRequired: false,
    },
    {
      id: 5,
      name: "Tennis",
      key: "Tennis",
      redirectionUrl: "/spmsports/tennis",
      // image: Tennis,
      authRequired: false,
    },
    {
      id: 6,
      name: "Casino",
      key: "casino",
      redirectionUrl: "/allCasino",
      // image: Horse,
      authRequired: false,
    },
    {
      id: 7,
      name: "Horse Racing",
      key: "horse",
      redirectionUrl: "/spmsports/horse-racing",
      // image: Horse,
      authRequired: false,
    },
    // {
    //   id: 8,
    //   name: "Greyhound Racing",
    //   key: "greyhound",
    //   redirectionUrl: "/spmsports/cricket",
    //   // image: Greyhound,
    //   authRequired: false,
    // },
    {
      id: 9,
      name: "Exchange",
      key: "ipl",
      redirectionUrl: "/spmsports/cricket",
      // image: EvolutionImg,
      authRequired: false,
    },
    // {
    //   id: 10,
    //   name: "Politics",
    //   key: "politics",
    //   redirectionUrl: "/sportsbook/Politics",
    //   // image: AvitorImg,
    //   authRequired: false,
    // },
    {
      id: 11,
      name: "Int Casino",
      key: "intcasino",
      redirectionUrl: "/allCasino",
      // image: LCImg,
      authRequired: false,
    },
    {
      id: 12,
      name: "Sports Book",
      key: "sportsbook",
      redirectionUrl: "/betby",
      // image: EvolutionImg,
      authRequired: false,
    },
    // {
    //   id: 11,
    //   name: "Qtech",
    //   key: "qtech",
    //   redirectionUrl: "/qtechlobby",
    //   // image: QtechImg1,
    //   authRequired: true,
    // },
    // {
    //   id: 12,
    //   name: "Supernowa",
    //   key: "supernowa",
    //   redirectionUrl: "/casino/supernowa",
    //   // image: SupernowaImg,
    //   authRequired: true,
    // },
    // {
    //   id: 13,
    //   name: "World Casino",
    //   key: "worldCasino",
    //   redirectionUrl: "/casino/worldcasino",
    //   // image: WCImg,
    //   authRequired: true,
    // },
    // {
    //   id: 14,
    //   name: "Slots",
    //   key: "slots",
    //   redirectionUrl: "/Slots",
    //   // image: SlotsImg,
    //   authRequired: true,
    // },
    // {
    //   id: 15,
    //   name: "Ezugi",
    //   key: "ezugi",
    //   redirectionUrl: "/casino/ezugi",
    //   // image: EzugiImg,
    //   authRequired: true,
    // },
  ];
  const location = useLocation();

  const checkIfActive = (key) => {
    // console.log("location", location);
    return key === location?.state?.activePage;
  };

  return (
    <div className="Bottom_head">
      <ul>
        {Array.isArray(headerItems) &&
          headerItems.map((item, index) => {
            return (
              <li className="" key={index}>
                {/* <img
                src={item?.image}
                alt="AvitorImg"
                className="d-md-none d-block"
              /> */}
                <a
                  onClick={() => {
                    if (isAuth || item.key === "home") {
                      window.location.href = item.redirectionUrl;
                    } else {
                      isMobile
                        ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                        : navigate("/sign-in");
                    }

                    // if (item?.authRequired === false) {
                    //   navigate(item.redirectionUrl, {
                    //     state: {
                    //       activePage: item?.key,
                    //     },
                    //   });
                    // } else {
                    //   isMobile
                    //     ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                    //     : navigate("/sign-in");
                    // }
                  }}
                  className={checkIfActive(item?.key) ? "active" : ""}
                >
                  {item?.name}
                </a>
              </li>
            );
          })}
      </ul>
      {showAuthModals && (
        <ModalWrapper
          defaultShow={defaultModal}
          handleClose={setShowAuthModals}
        />
      )}
    </div>
  );
};

export default BottomHead;
